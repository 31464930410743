.cover_thumb {
    width: 400px;
    /* max-height: 50vh; */
}

.cover_container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

}