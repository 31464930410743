/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #004b91;
// $warning: #f8991d;

.theme-navbar {
    background-color: #3f4248;
    color: #fff;

    .nav-link {
        color: #fff;
    }

    .nav-link:hover {
        color: #fff;
        text-decoration: underline;
        background-color: #54585f;
        border-radius: 2px;
    }
}

.theme-footer {
    background-color: #3f4248;
    color: #fff;

    a {
        color: #fff;
    }

    a:hover {
        color: #cecece
    }
}

.brand-logo {
    height: auto;
    width: 190px;
}

// SWIPPER STYLES
.swiper-pagination-bullet-active {
  background-color: #004b91 !important;
}
.swiper-button-prev {
  color: #004b91 !important;
}
.swiper-button-next {
  color: #004b91 !important;
}
.swiper-wrapper{
    height: 94% !important; 
}

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss"